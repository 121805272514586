<template>
  <!-- 67: C and 70: F -->
  <div
    class="backoffice"
    tabindex="0"
    v-on:keyup.ctrl.alt.67="copyId('client')"
    v-on:keyup.ctrl.alt.70="copyId('form')"
  >
    <v-app-bar app height="56" class="tw-shadow">
      <HeaderMenu :loading="loading" @toggleDrawer="toggleDrawer" />

      <v-spacer></v-spacer>

      <!-- <HeaderSearch :loading="loading" /> -->

      <HeaderModules :loading="loading" :modules="modules" :current-module="currentModule" @update="updateModule" />

      <!-- <HeaderNotifications :loading="loading" /> -->

      <HeaderAccount :loading="loading" :current-user="currentUser" @update="updateModule" />
    </v-app-bar>
    <Navigation
      :drawer="drawer"
      :loading="loading"
      :menuing="menuing"
      :navigation="currentNavigation"
      :brands="brands"
      :current-module="currentModule"
      :current-brand="currentBrandUuid"
      @toggleDrawer="toggleDrawer"
      @updateBrand="updateBrand"
    />
    <v-main>
      <v-overlay :value="loading || menuing" v-if="loading || menuing" absolute>
        <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
      </v-overlay>
      <div v-else>
        <v-progress-linear v-if="working" class="backoffice__progress-bar" indeterminate color="primary" />

        <router-view
          class="backoffice__view"
          :key="currentBrandUuid"
          :current-user="currentUser"
          :client-id="$route.params.clientId"
          @toggleDrawer="toggleDrawer"
        />
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getUserToken, isNavigationAccess, redirectToExternalModule, isInternalModule } from '@/utils/auth.util'
import { menus } from '@/config/menus.config'
import { sleep } from '@/utils/utilities.util'
import { URLS } from '@/config/urls.config'
import { redirections } from '@/config/redirections.config'
import { createCrispScript } from '@/utils/crisp.util'
import Navigation from '@/components/Navigation.vue'
import HeaderMenu from '@/components/Header/Menu.vue'
// import HeaderSearch from '@/components/Header/Search.vue'
import HeaderModules from '@/components/Header/Modules.vue'
// import HeaderNotifications from '@/components/Header/Notifications.vue'
import HeaderAccount from '@/components/Header/Account.vue'

export default {
  name: 'Backoffice',
  components: {
    Navigation,
    HeaderMenu,
    // HeaderSearch,
    HeaderModules,
    // HeaderNotifications,
    HeaderAccount,
  },
  created() {
    createCrispScript()
  },
  mounted() {
    this.initialization()
  },
  watch: {
    currentBrand: {
      async handler(newClient, oldClient) {
        const clientIdParam = this.$route.params.clientId
        const isFirstUrl = oldClient?.uuid ? false : true

        // If it's the first page loaded, and there is a clientId param different from the store one
        if (isFirstUrl && clientIdParam && newClient.uuid !== clientIdParam) {
          return this.setClientByUuid(clientIdParam)
        }

        if (newClient.uuid !== clientIdParam) {
          this.$router.push({
            params: { ...this.$route.params, clientId: newClient.uuid },
            query: this.$route.query,
            hash: this.$route.hash,
          })
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      drawer: state => state.backoffice.drawer,
      loading: state => state.backoffice.loading,
      menuing: state => state.backoffice.menuing,
      updating: state => state.backoffice.updating,
      currentUser: state => state.backoffice.currentUser,
      currentModule: state => state.backoffice.currentModule,
      currentBrand: state => state.backoffice.currentClient,
      currentNavigation: state => state.backoffice.currentNavigation,
      modules: state => state.backoffice.modules,
      brands: state => state.backoffice.brands,
      working: state => state.backoffice.working,
      currentForm: state => state.leads.currentForm,
    }),
    ...mapGetters({
      firstParentMenu: 'backoffice/firstParentMenu',
      firstChildMenu: 'backoffice/firstChildMenu',
    }),
    currentBrandUuid() {
      return this.currentBrand?.uuid
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      setDrawer: 'backoffice/setDrawer',
      setLoading: 'backoffice/setLoading',
      setMenuing: 'backoffice/setMenuing',
      updateCurrentNavigation: 'backoffice/updateCurrentNavigation',
      setNavigation: 'backoffice/setNavigation',
      getAuthentication: 'backoffice/getAuthentication',
      getClient: 'backoffice/getClient',
      setClientByUuid: 'backoffice/setClientByUuid',
      userLogout: 'backoffice/userLogout',
      resetAutoRreplyState: 'autoreply/resetState',
      resetTouchpointsState: 'touchpoints/resetState',
      resetLeadsState: 'leads/resetState',
      resetProfileHubState: 'profilehub/resetState',
    }),
    async initialization() {
      this.setLoading(true)
      await this.checkAuthentication()

      const route = this.$route
      const moduleByParams = route.params.module

      await redirectToExternalModule(this.currentModule, route.params.clientId)
      if (!moduleByParams) this.goToFirstMenu()
      this.setLoading(false)
    },
    toggleDrawer(bool) {
      this.setDrawer(typeof bool === 'boolean' ? bool : !this.drawer)
    },

    async copyId(type) {
      try {
        const idToCopy = type === 'client' ? this.currentBrand?.uuid : this.currentForm?.id

        if (idToCopy) {
          await navigator.clipboard.writeText(idToCopy)
          this.setAlert({
            color: 'success',
            text: this.$t('label.success'),
          })
        }
      } catch ($e) {
        this.setAlert({
          color: 'error',
          text: this.$t('label.fail'),
        })
      }
    },

    updateBrand(brand) {
      this.resetAutoRreplyState()
      this.resetProfileHubState()
      this.resetTouchpointsState()
      this.resetLeadsState()
      const route = redirections.find(redirection => redirection.from === this.$route.name)
      if (route) {
        this.$router.push(route.to)
      } else {
        this.$router.replace({})
      }
      this.getClient(brand.uuid)
    },
    async checkAuthentication() {
      if (getUserToken() !== undefined) {
        try {
          await this.getAuthentication()
          if (!isNavigationAccess(this.$route.meta.roles, this.currentUser)) {
            this.$router.push({ name: 'Error' })
          }
        } catch {
          await this.userLogin()
        }
      } else {
        await this.userLogin()
      }
    },
    async updateModule({ module, openNewTab }) {
      await redirectToExternalModule(module, this.$route.params.clientId, openNewTab)
      await isInternalModule(module, openNewTab)

      if (!openNewTab) {
        this.setMenuing(true)
        this.$store.commit('backoffice/SET_CURRENT_MODULE', module)
        this.updateCurrentNavigation(this.currentModule.module)
        this.goToFirstMenu()
      }
      this.setMenuing(false)
    },
    async goToFirstMenu() {
      const firstMenu = menus[this.firstParentMenu.key]?.items[this.firstChildMenu.key]
      const firstRouteMenu = firstMenu?.route
      const routeParams = firstMenu?.defaultParams || {}

      if (this.$route.name !== firstRouteMenu) {
        this.$router.push({
          name: firstRouteMenu,
          params: {
            ...routeParams,
            module: this.currentModule.module,
          },
        })
      }
    },
    async userLogin() {
      await this.userLogout()
      window.location.assign(
        `${URLS.SSO}/?app_version=3&redirect=${encodeURIComponent(location.pathname + location.search)}&sign_out=1`
      )
      await sleep(2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.backoffice {
  @include screen-flex;

  &__progress-bar {
    z-index: 1;
  }

  &__view {
    @include screen-flex($full-height: true);

    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-overflow-y-auto tw-overflow-x-hidden;
  }
}
</style>
