import { render, staticRenderFns } from "./Backoffice.vue?vue&type=template&id=03854758&scoped=true"
import script from "./Backoffice.vue?vue&type=script&lang=js"
export * from "./Backoffice.vue?vue&type=script&lang=js"
import style0 from "./Backoffice.vue?vue&type=style&index=0&id=03854758&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03854758",
  null
  
)

export default component.exports